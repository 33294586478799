@tailwind base;
@tailwind components;
@tailwind utilities;

/* React datepicker overrides */
.react-datepicker {
  border: none;
}

.react-datepicker__header {
  background: none;
}

.react-datepicker__day--selected {
  background: #1da765;
}

.schedule-calendar-wrapper .react-datepicker__month-container {
  border-width: 1px;
  border-color: rgba(206, 209, 212, 1);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.schedule-calendar-wrapper .react-datepicker__navigation {
  top: 0.5rem;
}

.async-select input:focus {
 box-shadow: none;
}

@import "./primereact-theme-seadragon.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";

/* Extend the container class on large screens */
@media screen and (min-width: 1536px) {
  .container {
    max-width: 2000px;
  }
}
